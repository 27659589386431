import ManagementSkills from "components/management/ManagementSkills/ManagementSkills";
import { LayoutHr } from "../LayoutHr/LayoutHr";

const ManagementPage = () => {
  return (
    <LayoutHr title="ERP - Management">
      <ManagementSkills />
    </LayoutHr>
  );
};

export default ManagementPage;
