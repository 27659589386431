import classes from "./Checkbox.module.scss";
import { WidgetComponent } from "forms/components/widget";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { useState } from "react";

const Checkbox = ({
  label,
  name,
  checked = false,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  resDataAfterSubmit,
  form,
}: WidgetComponent) => {
  const elId = `id_${name}`;

  const [wchecked, setChecked] = useState(checked);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );

  return (
    <section
      className={[classes.container, classes[name]].join(" ")}
      data-error={errorAfterSubmit ? true : undefined}
    >
      <input
        form={form}
        className={classes.input}
        name={name}
        id={elId}
        onChange={(e) => {
          setChecked(e.target.checked);
          onChange(e.target.checked);
        }}
        disabled={disabled}
        readOnly={readOnly}
        {...(inputArgs || {})}
        checked={wchecked}
        type="checkbox"
      />
      <label htmlFor={elId}>{label}</label>
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default Checkbox;
