import { IEmployeeMetic, ManagementEmployeeType } from "types";
import classes from "./DashboardTable.module.scss";
import { useApi } from "hooks/useApi/useApi";
import { useEffect, useMemo } from "react";
import { endpoints } from "API/endpoints";
import { Loader } from "components/ui/icons/Loader";
import { useStateRequest } from "hooks/useStateRequest/useStateRequest";
import { ru } from "date-fns/locale";
import { endOfWeek, startOfWeek, setDefaultOptions, format } from "date-fns";

setDefaultOptions({ locale: ru });

const DashboardTable = ({
  data,
  loading,
  users,
  filters,
}: {
  data: IEmployeeMetic[];
  loading: boolean;
  error?: string | null;
  users: ManagementEmployeeType[];
  filters?: {
    date?: null | Date | [Date | null, Date | null];
  };
}) => {
  const isEmpty = data.length === 0;

  const dateObjectRows = useMemo(() => {
    const obj: Record<string, IEmployeeMetic> = {};
    data.forEach((item) => {
      obj[item.pk.toString()] = item;
    });

    return obj;
  }, [users, data, filters]);

  const onClickTime = (employeeId: number) => {
    if(filters?.date && Array.isArray(filters?.date)){
      const start = format(
        filters?.date[0] || new Date(),
        "yyyy-MM-dd",
      );
      const end = format(filters?.date[0] || new Date(), "yyyy-MM-dd");
      window.open(
        `/reports?employee=${employeeId}&date_after=${end}&date_before=${start}`,
      );
    } else{
      const start = format(
        startOfWeek((filters?.date as Date) || new Date()),
        "yyyy-MM-dd",
      );
      const end = format(endOfWeek((filters?.date as Date) || new Date()), "yyyy-MM-dd");
      window.open(
        `/reports?employee=${employeeId}&date_after=${end}&date_before=${start}`,
      );
    }

  };

  return (
    <>
      <table className={classes.tabel}>
        <tr>
          <th>Всего дней</th>
          <th>Всего часов</th>
          <th>Простой</th>
          <th>Не отгружено</th>
          <th>Коммерческие</th>
          <th>Некомерческие</th>
          <th>Загруженность</th>
          <th>
            Коэффициент <br /> коммерческой <br />
            загрузки
          </th>
        </tr>
        {!loading &&
          !isEmpty &&
          users.map((item, index) => (
            <tr key={index}>
              <td>{dateObjectRows[item.id].metrics.days_count}</td>
              <td
                className={classes.tdHover}
                onClick={() => onClickTime(item.id)}
              >
                {dateObjectRows[item.id].metrics.hours_count}
              </td>
              <td>{dateObjectRows[item.id].metrics.downtime}</td>
              <td>{dateObjectRows[item.id].metrics.unloaded}</td>
              <td
                className={classes.tdHover}
                onClick={() => onClickTime(item.id)}
              >
                {dateObjectRows[item.id].metrics.commercial}
              </td>
              <td
                className={classes.tdHover}
                onClick={() => onClickTime(item.id)}
              >
                {dateObjectRows[item.id].metrics.not_commercial}
              </td>
              <td>{dateObjectRows[item.id].metrics.workload}</td>
              <td>{dateObjectRows[item.id].metrics.commercial_factor}</td>
            </tr>
          ))}
      </table>
      {isEmpty && !loading && (
        <div className={classes.empty}>Найдено записей 0</div>
      )}
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </>
  );
};

export const DashboardTableContainer = ({
  date,
  users,
}: {
  date: Date | null | [Date | null, Date | null];
  users: ManagementEmployeeType[];
}) => {
  const currentDate = Array.isArray(date) ? date[0] : date;
  const { apiGet } = useApi();
  const { loading, setLoading, setError, dataRequest, setDataRequest, error } =
    useStateRequest<IEmployeeMetic[]>({ initData: [] });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      let start = null;
      let end = null;
      if(Array.isArray(date)){
        start = format(date[0] || new Date(), "yyyy-MM-dd");
        end = format(date[1] || new Date(), "yyyy-MM-dd");
      } else{
        start = format(startOfWeek(currentDate || new Date()), "yyyy-MM-dd");
        end = format(endOfWeek(currentDate || new Date()), "yyyy-MM-dd");
      }

      let q = "";
      if (date) {
        q += `start_date=${start}&end_date=${end}`;
      }
      apiGet(endpoints.analiticsMetrics + (q !== "" ? "?" + q : ""))
        .then((res) => {
          setDataRequest(res.data);
        })
        .catch((e) => {
          setError(JSON.stringify(e));
        })
        .finally(() => setLoading(false));
    }
  }, [date]);

  return (
    <DashboardTable
      data={dataRequest}
      loading={loading}
      error={error}
      users={users}
      filters={{ date }}
    />
  );
};
