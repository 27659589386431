import Paper from "components/ui/paper/Paper";
import classes from "./Search.module.scss";
import CloseRed from "components/ui/icons/CloseRed";
import { useRef, useState } from "react";
import PlusRound from "components/ui/icons/PlusRound";

interface SearchProps {
  clazz?: string;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  form?: string;
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialValue?: string;
  autoFocus?: boolean;
  shadow?: boolean;
  autocomplite?: { education_link: string; id?: string; name: string }[];
  label?: string;
  clxs?: {
    clxsContainer?: string;
    clxsInput?: string;
    clxsAutocomplite?: string;
    clxsLabel?: string;
    clxsAutocompliteList?: string;
  };
  onClickAutocomplite?: (
    type: "add" | "sub" | "clear",
    value?: {
      id?: string | undefined;
      education_link: string;
      name: string;
    } | null,
  ) => void;
  onCreate?: (value: string) => void;
  onClear?: (value?: { label: string; value: string; name: string }[]) => void;
  selected?: { label: string; value: string; name: string; id?: string }[];
  multi?: boolean;
}

const Search = ({
  clazz,
  error,
  required,
  placeholder = "Название проекта",
  form,
  // id,
  onInput,
  initialValue,
  autoFocus,
  shadow = true,
  autocomplite,
  label,
  clxs,
  onClear,
  onCreate,
  onClickAutocomplite,
  selected = [],
  multi = false,
}: SearchProps) => {
  const {
    clxsContainer = "",
    clxsInput = "",
    clxsAutocomplite = "",
    clxsLabel = "",
    clxsAutocompliteList = "",
  } = clxs || {};
  const refInput = useRef<HTMLInputElement | null>(null);
  const [focus, setFocus] = useState(true);

  const handlerClick = (
    isActive: boolean,
    item: {
      education_link: string;
      id?: string;
      name: string;
    },
  ) => {
    onClickAutocomplite && onClickAutocomplite(isActive ? "sub" : "add", item);
    if (!multi) {
      onMouseLeave();
    }
  };
  /*
  const handlerInputAction = () => {
    if (selected.length > 0) {
      onClear && onClear(selected);
      onClickAutocomplite && onClickAutocomplite("clear");
    } else {
      onCreate && onCreate(initialValue || "");
    }
  };
  */
  const onMouseLeave = () => {
    refInput?.current?.blur();
    setFocus(false);
  };

  return (
    <div
      onMouseLeave={onMouseLeave}
      className={`${classes.searchWrap} ${
        autocomplite ? classes.padding : ""
      } ${clxsContainer}`}
    >
      {label && (
        <span className={classes.label + " " + clxsLabel}>{label}</span>
      )}
      <div className={`${classes.inputWrap}`}>
        <input
          ref={refInput}
          onFocus={() => setFocus(true)}
          autoFocus={autoFocus}
          type="search"
          placeholder={placeholder}
          required={required}
          form={form}
          className={`${classes.search} ${
            onClear || onCreate ? classes.inputEndAdornment : ""
          } ${clxsInput} ${shadow ? classes.shadow : ""} ${
            error ? classes["search--error"] : ""
          } ${clazz ? clazz : ""}`}
          onInput={onInput}
          defaultValue={initialValue}
          key={initialValue}
        />
        {(onClear || onCreate) && initialValue && (
          <button className={classes.endAdornment} type="button">
            {selected.length > 0 && onClear && <CloseRed />}
            {selected.length === 0 && onCreate && <PlusRound />}
          </button>
        )}
      </div>

      {autocomplite && autocomplite.length > 0 && focus && (
        <Paper
          cls={{
            root: classes.autocomplite + " " + clxsAutocomplite,
            paddingRoot: classes.autocomplitePadding,
          }}
        >
          <ul
            className={`${classes.autocompliteList} ${clxsAutocompliteList}`}
            data-element="list"
          >
            {autocomplite.map((item, index) => {
              const isActive = !!selected.find(
                (select) => select.id === item.id,
              );
              return (
                <li
                  className={`${classes.autocompliteListItem} ${
                    isActive ? classes.active : ""
                  }`}
                  key={index}
                  data-element="list-item"
                  onClick={() => handlerClick(isActive, item)}
                >
                  <span className={classes.autocompliteListItemName}>
                    {item.name}
                  </span>
                  {isActive ? <CloseRed /> : <PlusRound />}
                </li>
              );
            })}
          </ul>
        </Paper>
      )}
    </div>
  );
};

export default Search;
