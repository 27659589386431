interface IconProps {
  clazz?: string;
}

const ArrowRounded = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.75"
        y="0.75"
        width="14.5"
        height="14.5"
        rx="7.25"
        transform="matrix(-1 0 0 1 15 0.5)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M10.1001 5.30005L6.9001 8.50005L10.1001 11.7001"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowRounded;
