import Paper from "components/ui/paper/Paper";
import classes from "./ManagementHeader.module.scss";
import InputRadioButton from "components/ui/input-radio-button/InputRadioButton";
import { RoutsPath } from "types";
import { useLocation } from "react-router-dom";

type TManagementHeaderProps = {
  onChange: (value: string) => void;
};

const ManagementHeader = ({ onChange }: TManagementHeaderProps) => {
  const location = useLocation();
  return (
    <Paper>
      <div className={classes.tabs}>
        <InputRadioButton
          type="radio"
          name="tabs"
          checked={location.pathname === RoutsPath.hr_management}
          onChange={() => onChange(RoutsPath.hr_management)}
          className={classes.radio}
          label="Управление талантами"
        />
        <InputRadioButton
          type="radio"
          name="tabs"
          onChange={() => onChange(RoutsPath.hr_analitics)}
          checked={location.pathname === RoutsPath.hr_analitics}
          id="analytics"
          className={classes.radio}
          label="HR-аналитика"
        />
        <InputRadioButton
          type="radio"
          name="tabs"
          onChange={() => onChange("archive")}
          id="archive"
          className={classes.radio}
          label="Архив соискателей"
          disabled
        />
        <InputRadioButton
          type="radio"
          name="tabs"
          onChange={() => onChange("sach")}
          id="archive"
          className={classes.radio}
          label="САЧ"
          disabled
        />
      </div>
    </Paper>
  );
};

export default ManagementHeader;
