import { AnalyticsDashboard } from "components/analytics-hr";
import { LayoutHr } from "../LayoutHr/LayoutHr";

const AnaliticsPage = () => {
  return (
    <LayoutHr title="ERP - Analitics">
      <AnalyticsDashboard />
    </LayoutHr>
  );
};

export default AnaliticsPage;
