import React, { Children, isValidElement, cloneElement, useState, useContext, useEffect, PropsWithChildren } from "react";
import Button from "components/ui/button/Button";
import NumberInput from "forms/components/simpleWidgets/number-input/NumberInput";
import { UserInfoContext } from "context/UserInfoContext";
import classes from "../ManegementForms.module.scss";
import DateInput from "forms/components/simpleWidgets/date-input/DateInput";
import { parse } from "date-fns";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import EditTask from "components/ui/icons/EditTask";

type RowsSachType = {
  id: number;
  editor: string;
  cost: string;
  applied_from: string;
};

const EditableCell = ({ children, editable = false, value, onChange }: { onChange?: (value: any) => Promise<any>, value: any, editable?: boolean, name: string, children?: React.ReactNode }) => {
  const [editMode, setEditMode] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const save = (e: any) => {
    if (editedValue !== "" && onChange) {
      onChange(editedValue);
    };
    setEditMode(false);
    e.stopPropagation();
  };

  return <div onClick={() => editable && setEditMode(true)} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ cursor: editable ? "pointer" : undefined }}>
    {!editMode && <>
      {editable && hovered && <span style={{ marginRight: 10 }}><EditTask /></span>}
      {value}
    </>}
    {editMode && Children.map(children, child => {
      if (isValidElement(child)) {
        // @ts-expect-error TS2769
        return cloneElement(child, { onChange: val => setEditedValue(val) });
      } else {
        return child;
      }
    })
    }
    {editMode && <Button onClick={save}>Сохр.</Button>}
  </div>;
};




const FormUserSach = ({ labelBtn = "Создать", userId }: { onSucsess?: (data?: any) => void, labelBtn?: string, userId: number }) => {
  const [rows, setRows] = useState<RowsSachType[]>([]);
  const { userInfoContext } = useContext(UserInfoContext);
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const api = useApi();

  const onAddItem = () => {
    if (price !== "" && time !== "") {
      const newRecord = {
        editor: userInfoContext?.data.first_name || "unset",
        cost: price,
        applied_from: time
      };
      api.apiPost(`${endpoints.cah}?employer=${userId}`, { ...newRecord, employee: userId, }).then(data => {
        if (data.data) {
          setRows((prev) => [
            ...prev,
            { ...newRecord, id: data.data.id },
          ]);
          setTime("");
        }

      });

    }
  };

  const updateItem = async (id: number, patchData: Partial<RowsSachType>) => {
    const data = await api.apiPatch(`${endpoints.cah}${id}/?employer=${userId}`, {...patchData, partial: true});
    if (data.data) {
      setRows(oldRows => oldRows.map(oldRow => {
        if (oldRow.id === id) return data.data;
        return oldRow;
      }));
    };
  };

  useEffect(() => {
    api.apiGet(`${endpoints.cah}?employer=${userId}`).then(data => {
      setRows(data.data.data);
    });
  }, [userId]);

  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div
          className={`${classes.formItem} ${classes.pd32}`}
          data-type="user-sach"
        >
          <h3 className={classes.subtitle}>Стоимость административного часа</h3>
          <div className={classes.fieldsSach}>
            <NumberInput
              name={"price"}
              value={price}
              onChange={setPrice}
              label="Сумма"
              theme="outline"
              placeholder="Введите сумму"
            />
            <DateInput
              name={"applied_from"}
              value={time}
              onChange={(text) => setTime(text.toLocaleDateString())}
              label="С какой даты действует?"
              theme="outline"
            />
            <Button
              type="button"
              clazz={classes.buttonAdd}
              theme="fill"
              onClick={onAddItem}
            >
              Подтвердить
            </Button>
          </div>

          <div className={classes.tableWrap}>
            <h3 className={classes.subtitle}>История изменений</h3>
            <table className={classes.tabel}>
              <tr>
                <th>С какой даты действует?</th>
                <th>Кем изменено</th>
                <th>Стоимость</th>
              </tr>
              {rows.sort((a, b) => parse(b.applied_from, "dd.MM.yyyy", new Date()) > parse(a.applied_from, "dd.MM.yyyy", new Date) ? 1 : -1).map((item, index) => (
                <tr key={index}>
                  <td>
                    <EditableCell value={item.applied_from} name="applied_from" editable onChange={val => updateItem(item.id, { "applied_from": val.toLocaleDateString() })} >
                      <DateInput
                        name={"applied_from"}
                        value={item.applied_from}
                        onChange={() => null}
                        theme="outline"
                        autoFocus
                      />
                    </EditableCell>
                  </td>
                  <td><EditableCell value={item.editor} name="editor" /></td>
                  <td>
                    <EditableCell value={item.cost} name="cost" editable onChange={val => updateItem(item.id, { "cost": val })}>
                      <NumberInput
                        name={"price"}
                        value={item.cost}
                        onChange={() => null}
                        theme="outline"
                        placeholder="Введите сумму"
                        autoFocus
                      />
                    </EditableCell>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </form>
      <div className={classes.actions}>

      </div>
    </div>
  );
};

export default FormUserSach;
