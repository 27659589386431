import ManagementHeader from "components/management/ManagementHeader/ManagementHeader";
import { UserRoleContext } from "context/RoleContext";
import { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./LayoutHr.module.scss";

export const LayoutHr = ({
  children,
  title = "ERP",
}: {
  title?: string;
  children: ReactNode;
}) => {
  const { userRoleContext, isLoadingRole } = useContext(UserRoleContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    if (!isLoadingRole && !userRoleContext.data?.can_create_project) {
      navigate("/");
    }
  }, [isLoadingRole, userRoleContext]);

  const onChangeNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <section className={classes.mainContainer}>
      <ManagementHeader onChange={onChangeNavigation} />
      {children}
    </section>
  );
};
