import { useState, useRef, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  getMonth,
  getYear,
  endOfMonth,
  startOfMonth,
  endOfWeek,
  startOfWeek,
  startOfYear,
  endOfYear,
} from "date-fns";
import classes from "./DashboardSmallCalendar.module.scss";

import { ru } from "date-fns/locale";
import ArrowRounded from "components/ui/icons/ArrowRounded";
import Calendar2 from "components/ui/icons/Calendar2";

registerLocale("ru", ru);

export type RangeType = "week" | "year" | "month";

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const DashboardSmallCalendar = ({
  date,
  onChangeDate,
  modalOff = false,
  headerRange = false,
  defaultType = "week",
}: {
  date: Date | null | [Date | null, Date | null];
  onChangeDate: (
    value: Date | null | [Date | null, Date | null],
    type?: RangeType,
  ) => void;
  modalOff?: boolean;
  headerRange?: boolean;
  defaultType?: RangeType | null;
}) => {
  const currentDate = Array.isArray(date)
    ? date[0] || new Date()
    : date || new Date();
  const [month, setMonth] = useState<string>(months[currentDate.getMonth()]);
  const [year, setYear] = useState<number>(currentDate.getFullYear());
  const refCalendarWrap = useRef<null | HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [rangeType, setRangeType] = useState<RangeType>(defaultType || "week");
  const [rangeDate, setRangeDate] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const onClickNavgationCalendar = (nav: "next" | "prev") => {
    const newDate: Date = currentDate;
    if (headerRange) {
      if (rangeType === "month") {
        if (nav === "next") {
          newDate.setMonth(newDate.getMonth() + 1);
        } else {
          newDate.setMonth(newDate.getMonth() - 1);
        }
        onChangeDate([startOfMonth(newDate), endOfMonth(newDate)], rangeType);
        setRangeDate([startOfMonth(newDate), endOfMonth(newDate)]);
      } else if (rangeType === "year") {
        if (nav === "next") {
          newDate.setFullYear(newDate.getFullYear() + 1);
        } else {
          newDate.setFullYear(newDate.getFullYear() - 1);
        }
        onChangeDate([startOfYear(newDate), endOfYear(newDate)], rangeType);
        setRangeDate([startOfYear(newDate), endOfYear(newDate)]);
      } else {
        if (nav === "next") {
          newDate.setDate(newDate.getDate() + 7);
        } else {
          newDate.setDate(newDate.getDate() - 7);
        }
        onChangeDate([startOfWeek(newDate), endOfWeek(newDate)], rangeType);
        setRangeDate([startOfWeek(newDate), endOfWeek(newDate)]);
      }
    } else {
      if (nav === "next") {
        newDate.setMonth(newDate.getMonth() + 1);
      } else {
        newDate.setMonth(newDate.getMonth() - 1);
      }
      setRangeType("month");
      onChangeDate(startOfMonth(newDate), "month");
    }
    setMonth(months[newDate.getMonth()]);
    setYear(newDate.getFullYear());
  };

  const onChange = (values: Date | null | [Date | null, Date | null]) => {
    if (values && Array.isArray(values) && values[0] && headerRange) {
      let range: [Date | null, Date | null] = values;
      if (rangeType === "month" && values[0] !== null) {
        range = [startOfMonth(values[0]), endOfMonth(values[0])];
      } else if (rangeType === "year" && values[0] !== null) {
        range = [startOfYear(values[0]), endOfYear(values[0])];
      } else {
        range = [
          startOfWeek(values[0] || new Date()),
          endOfWeek(values[0] || new Date()),
        ];
      }
      setRangeDate(range);
      onChangeDate(range, rangeType);
    } else {
      onChangeDate(values);
    }

    if (Array.isArray(values) && values[0] !== null) {
      setMonth(months[getMonth(values[0])]);
      setYear(getYear(values[0]));
    } else if (!Array.isArray(values) && values !== null) {
      setMonth(months[getMonth(values)]);
      setYear(getYear(values));
    }
    setOpen(false);
  };

  const onClickTypeDate = (type: RangeType) => {
    let range: [Date | null, Date | null] = [null, null];
    if (type === "month") {
      range = [startOfMonth(currentDate), endOfMonth(currentDate)];
    } else if (type === "year") {
      range = [startOfYear(currentDate), endOfYear(currentDate)];
    } else {
      range = [startOfWeek(currentDate), endOfWeek(currentDate)];
    }
    setMonth(months[getMonth(range[0] || currentDate)]);
    setYear(getYear(range[0] || currentDate));
    setRangeDate(range);
    onChangeDate(range, type);
    setRangeType(type);
    setOpen(false);
  };

  useEffect(() => {
    if (!rangeDate[0]) onClickTypeDate(rangeType);
  }, []);

  return (
    <div
      className={classes.dashboarHeaderActionsCalendar + " " + classes.calendar}
    >
      <div className={classes.dashboarHeaderActionsCalendarContent}>
        <button type="button" onClick={() => onClickNavgationCalendar("prev")}>
          <ArrowRounded clazz={classes.arrowIconLeft} />
        </button>
        <div
          className={`${classes.dashboarHeaderActionsCalendarTitle} ${
            open ? classes.dashboarHeaderActionsCalendarOpen : ""
          }`}
          onClick={modalOff ? undefined : () => setOpen((prev) => !prev)}
        >
          <Calendar2 clazz={classes.dashboarHeaderActionsCalendarTitleIcon} />
          <span className={classes.dashboarHeaderActionsCalendarTitleText}>
            {rangeType !== "year" && month} {year}{" "}
            {rangeType === "year" && "год"}
          </span>
        </div>
        <button type="button" onClick={() => onClickNavgationCalendar("next")}>
          <ArrowRounded clazz={classes.arrowIconRight} />
        </button>
      </div>
      <div
        ref={refCalendarWrap}
        className={`${classes.dashboarCalendar} ${
          open ? classes.dashboarCalendarOpen : classes.dashboarCalendarClose
        }`}
      >
        <DatePicker
          calendarClassName="calendar-dashboard"
          renderCustomHeader={() => (
            <div
              className={classes.rangeType}
              style={{ display: headerRange ? "grid" : "none" }}
            >
              <button
                type="button"
                className={
                  classes.rangeTypeItem +
                  " " +
                  (rangeType === "week" ? classes.active : "")
                }
                onClick={() => onClickTypeDate("week")}
              >
                Неделя
              </button>
              <button
                type="button"
                className={
                  classes.rangeTypeItem +
                  " " +
                  (rangeType === "month" ? classes.active : "")
                }
                onClick={() => onClickTypeDate("month")}
              >
                Месяц
              </button>
              <button
                type="button"
                className={
                  classes.rangeTypeItem +
                  " " +
                  (rangeType === "year" ? classes.active : "")
                }
                onClick={() => onClickTypeDate("year")}
              >
                Год
              </button>
            </div>
          )}
          onChange={onChange}
          onClickOutside={() => {
            setOpen(false);
          }}
          locale="ru"
          calendarStartDay={1}
          selected={currentDate}
          startDate={headerRange ? rangeDate[0] : undefined}
          endDate={headerRange ? rangeDate[1] : undefined}
          showWeekPicker={rangeType === "week"}
          showMonthYearPicker={rangeType === "month"}
          showYearPicker={rangeType === "year"}
          selectsRange={headerRange}
          inline
          disabledKeyboardNavigation
        />
      </div>
    </div>
  );
};
