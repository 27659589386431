import { TProject, TTableColumns } from "components/tables/components/dto";

export interface TokenType {
  exp: number;
  iat: number;
  jti: string;
  token_type: string;
  user_id: number;
}

export interface ApiDataType<D = any, E = any> {
  data: D;
  errorMessage: E;
  status?: number | string;
}

export interface SkeletonType {
  count?: number;
  style?: React.CSSProperties;
  circle?: boolean;
  containerClassName?: string;
}

export interface userType {
  id: number;
  first_name: string;
  last_name: string;
  position: string;
  photo: string;
  department: string;
  slug: string;
}

export interface ProjectType {
  id: number;
  name: string;
  description: string;
  icon: string | null;
  date_start: string;
  date_end: string | null;
  wiki: string;
  telegram: string;
  planned_hours: string | number;
  spent_hours: string | number;
  slug: string;
  manager: string;
  link: string;
  department: string;
  statuses: string[];
}

export interface EmployeeType {
  photo?: string;
  first_name: string;
  last_name?: string;
  position: string;
  office?: string;
  inProject?: boolean;
  id: string;
  idInProject?: string;
  slug: string;
}

export interface ManagementEmployeeType {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  position: string;
  position_name: string;
  photo: string;
  department: string;
  slug: string;
  is_active?: boolean;
}

export interface IUserDetailBase {
  id: number;
  first_name: string;
  last_name: string;
  position: string;
  photo: string;
  department: string;
  slug: string;
  email: string;
  timezone: string;
  birthday: string;
  work_end_time: string;
  level: string;
  role: string;
  middle_name: string;
}

export interface IManagementEmployeFull extends IUserDetailBase {
  id: number;
  first_name: string;
  last_name: string;
  position: string;
  photo: string;
  department: string;
  slug: string;
  phone: string;
  tg?: string;
  position_grade: string;
  position_name: string;
  work_start_time: string;
  work_end_time: string;
  is_active?: boolean;
}

export interface SkillTabType {
  id: number;
  name: string;
  type: string;
}

export interface PlanningWeekDayType {
  date: string;
  day_month: string;
  is_workday: boolean;
  day_of_week: string;
}

export interface FormDataType {
  [key: string]: TInputValue;
}

export type TInputValue =
  | string
  | number
  | FileList
  | Date
  | string[]
  | number[];

export type PagesType = {
  current_page_number: number;
  items_per_page: number;
  total_pages: number;
  total_items: number;
};

export type ProjectsData = {
  data: ProjectsProps[];
  current_page_number: number;
  items_per_page: number;
  total_pages: number;
  total_items: number;
  columns: TTableColumns[];
};

export type useApiTableType = {
  columns: TTableColumns[];
  isLoading: boolean;
  data: TProject[];
  sortTable: (column: string) => void;
  updateData: (
    taskId: number,
    patchValues: PatchDataType | null,
    cell?: number,
    value?: string | number | Date,
  ) => Promise<ApiDataType>;
  deleteData: (taskId: number) => Promise<ApiDataType>;
  pages: PagesType;
  setPages: React.Dispatch<React.SetStateAction<PagesType>>;
};

export type PatchDataType = {
  [key: string]: string | number | Date | undefined | null | string[];
  task?: string | null;
  date?: string | null;
  project?: number | null;
  action_type?: string | null;
  spent_time_min?: string | null;
};

export interface ProjectsProps {
  name: string;
  icon?: string;
  id: number;
  date_start: string;
  date_end: string;
  planned_hours: string;
  spent_hours: string;
}

export interface IMetrics {
  days_count: number;
  hours_count: number;
  downtime: number;
  unloaded: number;
  commercial: number;
  commercial_factor: number;
  not_commercial: number;
  workload: number;
  outside: number;
}

export interface IEmployeeMetic {
  pk: number;
  first_name: string;
  last_name: string;
  metrics: IMetrics;
  position_name: string;
  photo: string;
}

export interface ICompetenceCategory {
  id: number;
  name: string;
  education_link: string;
}

export interface IEmplyeePositionGrade {
  id: number;
  grade: number;
  position: number;
  education_link: string;
  name: string;
}
export interface IEmplyeeGrade {
  id: number;
  name: string;
  level?: number;
}
export interface IEmplyeePosition {
  id: number;
  name: string;
  responsibilities_link: string;
}
export interface ICalendarUserWorkTimeItem {
  date: string;
  worked_time_count: number;
}
export interface ICalendarUserItem {
  pk: number;
  first_name: string;
  last_name: string;
  position_name: string;
  photo: string;
  hours_count: number;
  worked_time: ICalendarUserWorkTimeItem[];
}

export interface ICalendarUserData {
  work_days_count: number;
  data: ICalendarUserItem[];
}

export enum RoutsPath {
  hr = "/hr",
  hr_management = "/hr/management",
  hr_analitics = "/hr/analitics",
}
